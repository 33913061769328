import React, { useState, useEffect } from "react"
import Layout from "../../../containers/layout"
import { Accordion, Box, Text, AccordionPanel } from "grommet"
import { getZhiRunHourChart as getHourChart } from "../../../cm-lib/QiMen"
import { format, parseISO } from "date-fns"
import Common from "./common"
import data from "../../../data/execution.json"
import DateTimePicker from "../../../components/DateTimePicker"
import { assign } from "../../../helpers"
const queryString = require("query-string")

const isBrowser = typeof window !== `undefined`

const Home = () => {
  const qs = isBrowser && queryString.parse(window && window.location.search)
  const [description, setDescription] = useState(null)
  const [ju, setJu] = useState(null)
  const [profile, setProfile] = useState(null)
  const [mode, setMode] = useState(qs.mode || "Profile")
  const dt = isNaN(parseISO(qs.dt).getTime()) ? new Date() : parseISO(qs.dt)

  useEffect(() => {
    const foundChart = getHourChart(dt)
    setJu(foundChart.ju)
    setProfile(
      foundChart.profile &&
        foundChart.profile.map(e => ({
          title: `${e.title}: ${e.label}`,
          description: e.description,
        }))
    )
  }, [])
  useEffect(() => {
    if (mode === "Profile") {
      profile && setDescription(profile)
    } else {
      setDescription([])
    }
  }, [mode, profile])

  return (
    <Layout>
      <Common
        chartType="Hour Chart"
        onClick={date => {
          const dt = format(date, "yyyy-MM-dd HH:mm")
          isBrowser && window.location.assign(`?dt=${dt}&mode=${mode}`)
        }}
        mode={mode}
        chart={ju}
        initialDate={dt}
        heading="Qi Men Dun Jia Hour Chart"
        onSquarePressed={e => {
          const categories = [
            "Business",
            "Relationship",
            "Education",
            "Health",
            "Lesuire",
          ]
          const executionlist = data
            .filter(
              search => e.heaven === search.heaven && e.earth === search.earth
            )
            .map((e, i) => ({
              title: `${e.heaven}-${e.earth} ${categories[i]}: ${e.rating}`,
              description: e.description,
            }))
          mode === "Execution" && setDescription(executionlist)
        }}
      />
      <DateTimePicker
        onClick={date => {
          const dt = format(date, "yyyy-MM-dd HH:mm")
          assign(`?dt=${dt}`)
        }}
        dateObject={dt}
      />
      <br />
      <Box direction="row-responsive">
        {[
          { color: "status-warning", mode: "Profile" },
          { color: "status-ok", mode: "Execution" },
        ].map((e, i) => (
          <Box
            key={i}
            onClick={() => setMode(e.mode)}
            align="center"
            color="white"
            background={e.mode === mode ? "brand" : "black"}
            fill="horizontal"
            pad="small"
          >
            <Text color="white"> {e.mode}</Text>
          </Box>
        ))}
      </Box>

      <Accordion>
        {description &&
          description.map((e, i) => (
            <AccordionPanel key={i} label={e.title}>
              {
                <Box pad="medium" background="light-2">
                  <Text>
                    {" "}
                    {(mode !== "Execution" ||
                      (mode === "Execution" && qs.more)) &&
                      e.description}{" "}
                  </Text>
                </Box>
              }
            </AccordionPanel>
          ))}
      </Accordion>
    </Layout>
  )
}

export default Home
